import styled from "styled-components/macro";

const Wrapper = styled.nav`
  border-bottom: solid;
  border-width: 1px;
  border-color: #cccccc;
  background-color: white;
  height: 7rem;
  width: 100%;
  .navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem;
    gap: 1vw;
    /* border-bottom: solid;
    border-width: 1px;
    border-color: #cccccc;
    background-color: white;
    height: 7rem; */
  }

  .FaBars {
    font-size: 8vw;
    color: var(--primary-blue);
  }

  .toggle-button {
    background-color: transparent;
    border-style: none;
    :hover {
      cursor: pointer;
    }
  }

  .current-section {
    text-transform: capitalize;
    font-size: 1.8rem;
    max-height: 4vh;
  }

  .account-button-container {
    display: flex;
    flex-direction: column;
  }
  .account-button {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    margin: 0;
    padding: 0.5;
    font-size: 1.3rem;
    width: 8rem;
  }

  .active-button {
    background: var(--primary-blue-dark);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .account-button-icons {
    font-size: 1rem;
  }

  .dropdown-container {
    position: absolute;
  }

  @media screen and (min-width: 769px) {
    width: 100%;
    .FaBars {
      font-size: 2vw;
    }

    .navbar-wrapper {
      padding: 1rem;
      gap: 1.5vw;
    }

    .account-button {
      flex-direction: row;
      justify-content: space-between;
      width: 12rem;
    }

    .current-section {
      font-size: 2rem;
    }
  }
`;
export default Wrapper;
