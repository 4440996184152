import React from "react";
import Wrapper from "../assets/wrappers/BigSidebar.js";
import { NavLinks } from "./index";
import { Logo } from "./index";
import { useSelector } from "react-redux";

const BigSidebar = () => {
  const { showSidebar } = useSelector((store) => store.general);
  return (
    <Wrapper>
      <div className={`sidebar-container ${showSidebar && "show-sidebar"}`}>
        <div className="big-screen-logo">
          <Logo height={`7rem`} logoWidth={"5rem"} />
        </div>
        <div className="small-screen-logo">
          <Logo height={`6rem`} logoWidth={"4rem"} />
        </div>
        <NavLinks />
      </div>
    </Wrapper>
  );
};

export default BigSidebar;
