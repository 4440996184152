import styled from "styled-components/macro";

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 1rem;
  width: 90vw;
  background: white;
  border-radius: 10px;

  margin-top: 10vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .register-button {
    /* margin: 0 auto;
    padding: 0.5rem;
    border-radius: 5px;
    border-style: none;
    text-decoration: none;
    background-color: var(--primary-blue);
    color: white; */

    font-size: 1.5rem;
    display: block;
    margin: 0 auto;
    width: 40vw;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    :hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
  }

  // title would overwrite the logo title
  .title-reset-password {
    font-size: 8vw;
    font-weight: 500;
    text-align: center;
  }

  @media screen and (min-width: 769px) {
    width: 20vw;
    .title-reset-password {
      font-size: 1.5vw;
      font-weight: 500;
      text-align: center;
    }

    .register-button {
      width: 19vw;
      margin-top: 5vh;
    }
  }
`;
export default Wrapper;
