import styled from "styled-components/macro";

const Wrapper = styled.div`
  padding: 1rem;
  /* width: 70vw; */

  .settings-box {
    padding: 0.5rem;
    background: white;
    border-radius: 5px;
    width: 70vw;
    display: flex;
    flex-direction: column;
    transition: 0.3s ease-in-out all;
  }
  .hide-sidebar {
    width: 90vw;
    transition: 0.3s ease-in-out all;
  }
  .category-container {
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: var(--primary-black-80);
    :hover {
      background: hsl(0, 0%, 97%);
    }
  }
  .category {
    font-weight: bold;
    width: 7rem;
  }
  .category-content {
    width: 8rem;
  }

  .edit-button-container {
    display: flex;
    justify-content: center;
  }
  .edit-button {
    border-style: none;
    background: transparent;
    color: var(--primary-black-60);
    font-size: 1rem;
    :hover {
      color: var(--primary-black-40);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .edit-section {
    padding: 0.2rem;
    width: 14rem;
    margin-left: 1rem;
  }

  .form-container {
    margin-left: -1.2rem;
    padding: 1rem;
  }

  .edit-section-buttons {
    .change-button {
      background: var(--primary-green-40);
      margin-right: 4rem;
      :hover {
        background: var(--primary-green-30);
      }
    }
    .decline-button {
      background: var(--primary-red-60);
      :hover {
        background: var(--primary-red-40);
      }
    }
  }

  @media screen and (min-width: 769px) {
    width: 100%;

    .category-container {
      font-size: 1.3rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 24rem;
    }
    .category-content {
      width: 14rem;
    }
    .edit-section {
      padding: 0.2rem;
      width: 24rem;
      margin-left: 1rem;
    }

    .edit-section-buttons {
      .change-button {
        margin-right: 4rem;
      }
    }
  }
`;

export default Wrapper;
