import React, { useState } from "react";

import Wrapper from "../../assets/wrappers/ProfileSettings.js";
import { FormRow, Alert } from "../../components/index.js";
import { useSelector } from "react-redux";
import { logoutUser, updateUser } from "../../features/user/userSlice.js";
import { useDispatch } from "react-redux";
import { useUpdateUserMutation } from "../../features/api/apiSlice.js";

const initialState = {
  name: "",
  email: "",
  password: "",
};

const Settings = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((store) => store.user);
  const { showSidebar, showAlert } = useSelector((store) => store.general);
  const [interimUserData, setInterimUserData] = useState(userData);
  const Categories = [
    { name: "Name", type: "text", value: "name" },
    { name: "Email", type: "email", value: "email" },
    { name: "Password", type: "text", value: "password" },
  ];

  const [editId, setEditId] = useState();

  const selectEdit = (id) => {
    setEditId(id);
  };

  const handleChange = (e) => {
    const targetName = e.target.name.toLowerCase();
    const value = e.target.value;

    // irgendwie scheint er probleme haben name unten "anzuvisieren"
    // du musst toLowerCase, da er sonst den targetNamen groß hat und dann unten auch groß ihn deswegen in "userData" nicht mehr findet

    setInterimUserData({ ...userData, [targetName]: value });
  };

  const declineChange = () => {
    setInterimUserData(userData);
    setEditId(null);
  };

  const acceptChange = () => {
    onSaveClicked();
    setEditId(null);
  };

  const handleSubmit = (e) => {
    e.prevent.default();
  };

  // updateUserAPI
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { email, name, password } = interimUserData;

  const onSaveClicked = async () => {
    try {
      await updateUser({ name, email, password }).unwrap();
    } catch (err) {
      console.error("Failed to updateUser", err);
    }
  };

  return (
    <Wrapper>
      {showAlert && <Alert />}
      <form onSubmit={handleSubmit}>
        <div className={`settings-box ${!showSidebar && "hide-sidebar"}`}>
          {[...Array(3)].map((item, id) => {
            if (id === editId)
              return (
                <div className="edit-section" key={Categories[id].name}>
                  <div className="form-container">
                    <FormRow
                      className="edit-field"
                      type={Categories[id].type}
                      name={Categories[id].name}
                      value={
                        interimUserData[`${Categories[id].name.toLowerCase()}`]
                      }
                      labelText={Categories[id].name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="edit-section-buttons">
                    <button
                      className="general-button change-button"
                      onClick={acceptChange}
                      disabled={isLoading}
                    >
                      change
                    </button>
                    <button
                      className="general-button decline-button"
                      onClick={declineChange}
                    >
                      decline
                    </button>
                  </div>
                </div>
              );

            return (
              <div className="category-container" id={id} key={id}>
                <p className="category">{`${Categories[id].name}:`}</p>
                <p className="category-content">
                  {Categories[id].name === "Password"
                    ? "*****"
                    : userData[`${Categories[id].name.toLowerCase()}`]}
                </p>
                <div className="edit-button-container">
                  <button
                    className="edit-button"
                    onClick={() => {
                      selectEdit(id);
                      setInterimUserData(userData);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            );
          })}
          {/* <div className="category-container">
          <p className="category">Name:</p>
          <p className="category-content">Jannis</p>
          <button className="edit-button">Edit</button>
        </div>
        <div className="category-container">
          <p className="category">Mail:</p>
          <p className="category-content">Jannis@mail.com</p>
          <button className="edit-button">Edit</button>
        </div>
        <div className="category-container">
          <p className="category">Password:</p>
          <p className="category-content">*****</p>
          <button className="edit-button">Edit</button>
        </div> */}
        </div>
      </form>
    </Wrapper>
  );
};

export default Settings;
