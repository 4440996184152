import React from "react";
import Wrapper from "../assets/wrappers/FormRowSelect";

const FormRowSelect = ({
  labelText,
  name,
  value,
  onChange,
  list,
  classLabel,
  classInput,
}) => {
  return (
    <Wrapper>
      <div className="form-row">
        <label htmlFor={name} className={classLabel || "form-label"}>
          {labelText || name}
        </label>
        <select
          className={classInput || "form-select"}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
        >
          {list.map((itemValue, index) => {
            return (
              <option key={index} value={itemValue}>
                {itemValue}
              </option>
            );
          })}
        </select>
      </div>
    </Wrapper>
  );
};

export default FormRowSelect;
