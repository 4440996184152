import React, { useState } from "react";
import Wrapper from "../assets/wrappers/ResetPassword.js";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../features/api/apiSlice.js";
import { Logo, FormRow } from "../components";
import { displayAlert, hideAlert } from "../features/general/generalSlice.js";
import { Loading } from "../components";

const initialState = {
  newpassword: "",
  repeatnewpassword: "",
  secretpasswordcode: "",
};

const ResetPassword = () => {
  const [values, setValues] = useState(initialState);
  let [searchParams, setSearchParams] = useSearchParams();
  const passwordToken = searchParams.get("password_token");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(passwordToken);

  const { newpassword, repeatnewpassword, secretpasswordcode } = values;

  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();

  const { showAlert, alertText, alertType } = useSelector(
    (store) => store.general
  );
  const handleChange = (e) => {
    const name = e.target.name.toLowerCase();
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  const onSaveClicked = async () => {
    try {
      await resetPassword({
        passwordToken,
        password: newpassword,
        secretPasswordCode: secretpasswordcode,
        email,
      }).unwrap();
    } catch (err) {
      console.error("Failed to reset password", err);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!newpassword || !repeatnewpassword || !secretpasswordcode) {
      dispatch(
        displayAlert({
          alertText: "Please provide all values",
          alertType: "fail",
        })
      );
    }

    if (newpassword !== repeatnewpassword) {
      dispatch(
        displayAlert({
          alertText: "Passwords dont match",
          alertType: "fail",
        })
      );
    }

    if (
      newpassword &&
      repeatnewpassword &&
      secretpasswordcode &&
      newpassword === repeatnewpassword
    ) {
      onSaveClicked();
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  if (isSuccess) {
    return (
      <Wrapper>
        <header>
          <Logo />
        </header>
        <p>Password was successfully reset, login now</p>
        <button
          className="register-button general-button"
          type="button"
          onClick={() => navigate("/register")}
        >
          To Login Page
        </button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <header>
        <Logo />
      </header>
      <form onSubmit={onSubmit}>
        <p className="title-reset-password">Reset Password</p>

        <div className="form-container">
          <FormRow
            type={"text"}
            name={"secretPasswordCode"}
            labelText={"Secret Password Code"}
            onChange={handleChange}
          ></FormRow>
        </div>

        <div className="form-container">
          <FormRow
            type={"password"}
            name={"newPassword"}
            labelText={"new Password"}
            onChange={handleChange}
          ></FormRow>
        </div>
        <div className="form-container">
          <FormRow
            type={"password"}
            name={"repeatNewPassword"}
            labelText={"repeat new Password"}
            onChange={handleChange}
          ></FormRow>
        </div>

        <button
          className="register-button general-button"
          type="submit"
          // onClick={() => onSaveClicked()}
        >
          Submit
        </button>
      </form>
    </Wrapper>
  );
};

export default ResetPassword;
