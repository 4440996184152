import axios from "axios";

const customFetch = axios.create({
  baseURL: "http://api.dnptf.com",
  // baseURL: "/api/v1/",

  //logout, when error is 401
});

export default customFetch;
