import React, { useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../assets/wrappers/AccountSettings";
import { useDispatch } from "react-redux";
import { useLogoutUserMutation } from "../features/api/apiSlice";
import { currentSection } from "../features/user/userSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { apiSlice } from "../features/api/apiSlice";
import { Loading } from "../components";
const AccountSettings = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [logoutUser, { isLoading }] = useLogoutUserMutation();

  const onSaveClicked = async () => {
    // dispatch(apiSlice.util.resetApiState());
    try {
      await logoutUser();
      window.location.reload();
    } catch (err) {
      console.error("Failed to logout: ", err);
    }
  };
  console.log(apiSlice.util);
  return (
    <Wrapper>
      <div className="button-container">
        <Link to="/settings">
          <button
            className="button"
            onClick={() => {
              dispatch(currentSection({ section: "Profile Settings" }));
              props.toggleAccountSettings();
            }}
          >
            Settings
          </button>
        </Link>
        <button
          className="button last-button"
          onClick={() => {
            onSaveClicked();
            // dispatch(apiSlice.util.resetApiState());
          }}
        >
          Logout
        </button>
      </div>
    </Wrapper>
  );
};

export default AccountSettings;
