const addUserTagsToLocalStorage = (tags) => {
  localStorage.setItem("userTags", JSON.stringify(tags));
};

const removeUserTagsFromLocalStorage = () => {
  localStorage.removeItem("userTags");
};

const getUserTagsFromLocalStorage = () => {
  const result = localStorage.getItem("userTags");

  const tags = result ? JSON.parse(result) : [];

  return tags;
};

export {
  addUserTagsToLocalStorage,
  removeUserTagsFromLocalStorage,
  getUserTagsFromLocalStorage,
};
