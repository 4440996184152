import styled from "styled-components/macro";

const Wrapper = styled.main`
  .tasks-container {
    margin-top: 2rem;
    padding: 2vw;
    transition: 0.3s ease-in-out all;
    width: 80vw;
    .show-button {
      margin-top: -2rem;
      margin-bottom: -2rem;
      font-size: 1.2rem;
    }

    @media screen and (min-width: 769px) {
      margin: 0 auto;
      width: 82vw;
      padding: 1vw;
      transition: none !important;
    }
  }
  //hide-sidebar for mobile
  .hide-Sidebar {
    transition: 0.3s ease-in-out all;
    margin: 0 auto;
    margin-top: 2rem;
    width: 96vw;
  }

  @media screen and (min-width: 769px) {
    .hide-Sidebar {
      transition: none !important;
      margin: 0 auto;
      margin-top: 2rem;
      width: 82vw;
    }
  }
`;

export default Wrapper;
