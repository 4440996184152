import styled from "styled-components/macro";

const Wrapper = styled.div`
  .diarycontainer-container {
    .banner {
      margin-top: 1rem;
      padding-top: 0.5rem;
      color: white;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(67, 67, 210, 1) 25%,
        rgba(0, 212, 255, 1) 100%
      );

      height: 2.6rem;
      display: flex;
      padding-left: 5rem;
      font-size: 1.4rem;

      border-radius: 3px;
    }
  }
  .hide-sidebar {
    width: 100vw;
  }

  @media screen and (min-width: 769px) {
    .diarycontainer-container {
      .banner {
        width: 100vw;
      }
    }
  }
`;

export default Wrapper;
