import styled from "styled-components/macro";

const Wrapper = styled.main`
  header {
    display: flex;
    padding: 1rem;
  }
  .header-logo {
    width: 100%;
    object-fit: contain;
    max-width: 8vw;
  }

  .header-title {
    text-transform: capitalize;
    font-size: 6vw;
    color: var(--primary-violett);
    margin-left: 1rem;
    align-self: center;
  }

  .landing-button {
    padding: 0.5rem;
    font-size: 1.5rem;
    border-radius: 5px;
    border-style: none;
    text-decoration: none;
    background-color: var(--primary-blue);
    color: white;
    margin-top: 10vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    :active {
      background-color: var(--primary-blue-dark);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      transform: scale(1.2);
      transition: 0.1s;
    }
  }

  .landing-button-container {
    margin-top: 8vh;
  }
  .landing-main {
    margin-top: 10vh;
  }

  margin: 0 auto;
  margin-top: 2rem;
  width: 80%;

  text-align: center;

  @media screen and (min-width: 769px) {
    .header-logo {
      max-width: 6vw;
    }
    .header-title {
      font-size: 2vw;
    }

    .landing-button {
      :hover {
        background-color: var(--primary-blue-dark);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
          rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      }
    }
  }
`;

export default Wrapper;
