import React, { useState } from "react";
import Wrapper from "../assets/wrappers/ResetPassword.js";
import { useDispatch, useSelector } from "react-redux";
import { Logo, FormRow } from "../components";
import { useForgotPasswordMutation } from "../features/api/apiSlice.js";
import { displayAlert, hideAlert } from "../features/general/generalSlice.js";
import { Loading } from "../components";

const initialState = {
  email: "",
};

const ForgotPassword = () => {
  const [values, setValues] = useState(initialState);
  const dispatch = useDispatch();

  const [forgotPassword, { isLoading, isSuccess }] =
    useForgotPasswordMutation();

  const { showAlert, alertText, alertType } = useSelector(
    (store) => store.general
  );

  const { email } = values;

  const handleChange = (e) => {
    const name = e.target.name.toLowerCase();
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  const onSaveClicked = async () => {
    try {
      await forgotPassword({ email }).unwrap();
    } catch (err) {
      console.err("Failed to send ");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      dispatch(
        displayAlert({
          alertText: "Please provide all values",
          alertType: "fail",
        })
      );
    }

    if (email) {
      onSaveClicked();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess) {
    return (
      <Wrapper>
        <header>
          <Logo />
        </header>
        <p>Reset Mail was successfully sent</p>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <header>
        <Logo />
      </header>
      <form onSubmit={onSubmit}>
        <p className="title-reset-password">Forgot Password ?</p>
        <p>
          Please type your email into the field and we will send you a mail to
          reset your password
        </p>
        <div className="form-container">
          <FormRow
            type={"email"}
            name={"email"}
            labelText={"Email"}
            onChange={handleChange}
          ></FormRow>
        </div>

        <button
          className="register-button general-button"
          type="submit"
          onClick={() => onSaveClicked()}
        >
          Submit
        </button>
      </form>
    </Wrapper>
  );
};

export default ForgotPassword;
