import React from "react";
import Wrapper from "../assets/wrappers/FormRow";

const FormRow = ({
  type,
  name,
  value,
  labelText,
  onChange,
  classLabel,
  classInput,
}) => {
  return (
    <Wrapper>
      <label htmlFor={name} className={classLabel || "form-label"}>
        {labelText || name}
      </label>

      <input
        type={type}
        value={value}
        name={name}
        className={classInput || "form-input"}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default FormRow;
