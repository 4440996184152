import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Logo, AccountSettings } from "./index";
import Wrapper from "../assets/wrappers/Navbar.js";
import { FaBars, FaUserCircle } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { GoTriangleDown } from "react-icons/go";
import { toggleSidebar } from "../features/general/generalSlice";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const { showSidebar } = useSelector((store) => store.general);
  const userData = user.userData;

  //get the path for the title of the navbar
  const { pathname } = useLocation();
  const pathnameSplit = pathname.split("/");

  const toggleAccountSettings = () => {
    setShowAccountSettings(!showAccountSettings);
    return;
  };

  return (
    <Wrapper>
      <div className="navbar-wrapper">
        <button
          className="toggle-button"
          onClick={() => dispatch(toggleSidebar())}
        >
          <FaBars className="FaBars" />
        </button>

        <h2 className="current-section">{pathnameSplit[1]}</h2>

        <div className="account-settings-container">
          <button
            type="button"
            className={`general-button account-button ${
              showAccountSettings && "active-button"
            }`}
            onClick={toggleAccountSettings}
          >
            <FaUserCircle className="account-button-icons" />
            {(userData && userData.name) || "errorname"}
            <GoTriangleDown className="account-button-icons" />
          </button>
          {showAccountSettings && (
            <div className="dropdown-container">
              <AccountSettings toggleAccountSettings={toggleAccountSettings} />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Navbar;
