import { createSlice } from "@reduxjs/toolkit";
import allTasksSlice from "../allTasks/allTasksSlice";
import dayjs from "dayjs";
import { apiSlice } from "../api/apiSlice";

// cant access the monthList in initialState while declaration, so need another one
const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const lastMonthHandle = (month) => {
  if (month == "January") {
    return "December";
  }
  const monthListNumber = monthList.findIndex((item) => item === month);

  return monthList[monthListNumber - 1];
};
const initialState = {
  yearList: ["2023", "2022"],
  monthList: monthList,
  searchValuesDiary: {
    year: dayjs().year(),
    month: monthList[dayjs().month()],
  },
  lastMonth: monthList[dayjs().month() - 1],
};

// monthList[dayjs().month()];

const allDiarySlice = createSlice({
  name: "allDiary",
  initialState,
  reducers: {
    handleSearchDiaryChange: (state, { payload: { name, value } }) => {
      state.searchValuesDiary = { ...state.searchValuesDiary, [name]: value };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(apiSlice.endpoints.getAllDiary.initiate, (state) => {
      state.lastMonth = lastMonthHandle(state.searchValuesDiary.month);
    });
  },
});
// monthList[dayjs().month()];
export const { handleSearchDiaryChange } = allDiarySlice.actions;
export default allDiarySlice.reducer;
