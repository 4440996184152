import styled from "styled-components/macro";

const Wrapper = styled.div`
  .search-form-diary-container {
    margin: 0 auto;
    background: green;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 70vw;
    padding: 1.5vw;
    border-style: solid;
    border-radius: 3px;
    border-width: 1px;
    background: white;
    border-color: #b3e0ff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    height: 26vh;
    transition: 0.3s ease-in-out all;

    .create-button-container {
      border-radius: 3px;
    }
  }
  .search-form-diary-hide-sidebar {
    margin-left: 4vw;

    width: 90vw;
    padding: 4vw;
    transition: 0.3s ease-in-out all;
  }
  @media screen and (min-width: 769px) {
    .search-form-diary-container {
      margin-left: 4vw;
      width: 40vw;
      flex-direction: row;
      height: auto;

      .create-button-container {
        margin-top: 1.7rem;
      }
    }
    .search-form-diary-hide-sidebar {
      margin-left: 4vw;

      width: 40vw;
      padding: 4vw;
    }
  }
`;

export default Wrapper;
