import React from "react";
import { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Wrapper from "../assets/wrappers/DiaryEntryEditor";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const DiaryEntryEditor = ({ handleTextChange, textValue }) => {
  // const deltatest = editor.getContents();
  // console.log(deltatest);

  return (
    <Wrapper>
      <ReactQuill
        className="react-quill"
        theme="snow"
        value={textValue}
        onChange={(textValue) => handleTextChange(textValue)}
        modules={modules}
        formats={formats}
      ></ReactQuill>
    </Wrapper>
  );
};

export default DiaryEntryEditor;
