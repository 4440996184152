import React, { useEffect, useState } from "react";
import Wrapper from "../assets/wrappers/Verify.js";
import { Link, useSearchParams } from "react-router-dom";
import logo from "../assets/images/android-chrome-192x192.png";
import { Loading } from "../components";
import { useVerifyEmailMutation } from "../features/api/apiSlice.js";

const Verify = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [emailVerified, setEmailVerified] = useState("he");

  const verificationToken = searchParams.get("verification_token");

  const email = searchParams.get("email");
  console.log(email);

  const [verifyEmail, { isLoading: verifyEmailLoading, isSuccess }] =
    useVerifyEmailMutation();

  const verifyEmailClicker = async () => {
    try {
      await verifyEmail({
        verificationToken,
        email,
      })
        .unwrap()
        .then(setEmailVerified(false));
    } catch (err) {
      console.err("Failed to verify Email", err);
    }
  };

  useEffect(() => {
    if (!emailVerified) {
      verifyEmailClicker();
    }
    setEmailVerified(false);
  }, [emailVerified]);

  if (verifyEmailLoading) {
    return <Loading />;
  }

  if (isSuccess) {
    return (
      <Wrapper>
        <header>
          {/* lass das logo mitskalieren */}

          <img className="header-logo" src={logo} alt="logo"></img>
          <p className="header-title">
            <b>D</b>o <b>n</b>ot <b>p</b>lan <b>t</b>o <b>f</b>ail
          </p>
        </header>
        <div className="landing-main">
          <p>Email was successfully verified, please login</p>
          <div className="landing-button-container">
            {/* link margin is not stylabe???? what is with width?*/}
            <Link to="/register" className={"landing-button"}>
              Login
            </Link>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <header>
        {/* lass das logo mitskalieren */}

        <img className="header-logo" src={logo} alt="logo"></img>
        <p className="header-title">
          <b>D</b>o <b>n</b>ot <b>p</b>lan <b>t</b>o <b>f</b>ail
        </p>
      </header>
      <div className="landing-main">
        <p>There was an error, please try again</p>
      </div>
    </Wrapper>
  );
};
export default Verify;
