import React from "react";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCurrentUser } from "../features/user/userSlice.js";
import { hideAlert } from "../features/general/generalSlice.js";
import Wrapper from "../assets/wrappers/SharedLayout.js";
import { Outlet } from "react-router-dom";
import { Navbar, BigSidebar } from "../components";
import { useSelector } from "react-redux";

const SharedLayout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    dispatch(hideAlert());
  }, [pathname]);

  return (
    <Wrapper>
      <main className="dashboard">
        <BigSidebar />
        <div>
          <Navbar />
          <div className="outlet-page">
            <Outlet />
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default SharedLayout;
