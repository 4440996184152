import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import generalSlice from "./features/general/generalSlice";
import allTasksSlice from "./features/allTasks/allTasksSlice";
import allDiarySlice from "./features/allDiary/allDiarySlice.js";

import { apiSlice } from "./features/api/apiSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    general: generalSlice,
    allTasks: allTasksSlice,
    allDiary: allDiarySlice,
    api: apiSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
