import styled from "styled-components/macro";

const Wrapper = styled.main`
  .general-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    width: 36vw;
    height: 100%;
    background: white;
    border-color: #b3e0ff;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .expired {
    border-color: red;
  }

  .one-day-remaining {
    border-color: orange;
  }
  .one-week-remaining {
    border-color: #3385ff;
  }
  .thirty-days-remaining {
    border-color: #66a3ff;
  }

  .title {
    font-size: 0.9rem;
    height: 3rem;
  }

  .property {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0.5rem;
    background: var(--primary-tag-background);
    padding: 0.2rem;
    border-radius: 3px;
    font-size: 0.8rem;
    max-height: 4rem;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 36vw;
    min-height: 2vh;
  }
  .end-container {
    margin-top: auto;
  }
  .button-container {
    margin-top: auto; // places button an bottom
    display: flex;
    gap: 1.5rem;
    justify-content: right;
    /* align-self: flex-end; //places button at the bottom */
  }
  .edit-button {
    border-style: none;
    background: transparent;
    color: var(--primary-black-60);
    font-size: 1rem;

    :hover {
      color: var(--primary-black-40);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 769px) {
    .general-container {
      width: 18vw;
    }
    .icon {
      margin-right: 0.5rem;
      font-size: 1.4rem;
    }
    .property {
      display: flex;
      flex-direction: row;
      font-size: 1.1rem;
    }
    .tag-container {
      margin-right: 0.5rem;
      background: var(--primary-tag-background);
      padding: 0.2rem;
      border-radius: 3px;
    }
  }
`;

export default Wrapper;
