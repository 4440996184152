import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin-left: 4rem;
  .diary-container {
    margin-top: 0.5rem;
    height: 20rem;

    // put the arrows outside of the carousel
    position: relative;
  }

  .carousel {
    // put the arrows outside of the carousel
    position: unset;
    height: 20rem;

    // put the arrows outside of the carousel
    .react-multiple-carousel__arrow {
      position: absolute;
    }

    .react-multiple-carousel__arrow--left {
      left: calc(-10% - 22px);
    }

    .react-multiple-carousel__arrow--right {
      right: calc(-1% + 14px);
    }

    .react-multi-carousel-dot {
      button {
        background-color: var(--primary-blue-85);
        border-color: var(--primary-blue-85);
      }
    }

    .react-multi-carousel-dot--active {
      button {
        background-color: var(--primary-blue-dark);
        border-color: var(--primary-blue-dark);
      }
    }

    @media screen and (min-width: 769px) {
      .react-multiple-carousel__arrow--left {
        left: calc(-4% + 1px);
      }

      .react-multiple-carousel__arrow--right {
        right: calc(-4% + 1px);
      }
    }
  }
`;

export default Wrapper;
