import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../assets/wrappers/Diary.js";
import { SearchFormDiary, DiaryContainer } from "../../components";

import { useSelector } from "react-redux";

const Diary = () => {
  const { showSidebar } = useSelector((store) => store.general);

  return (
    <Wrapper>
      <SearchFormDiary showSidebar={showSidebar} />

      <DiaryContainer showSidebar={showSidebar} />
    </Wrapper>
  );
};

export default Diary;
