import styled from "styled-components/macro";

const Wrapper = styled.main`
  .tasks-container-container {
    // probiere es mal zu gridden, damit es mobil sich besser anpasst, bei mehreren tag unterschieden.
    /* margin: 0 auto; */
    margin: 0 auto;
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 4vw;
    transition: 0.3s ease-in-out all;
  }
  .hide-sidebar {
    /* max-width: none; */
    justify-content: space-around;
    transition: 0.3s ease-in-out all;
  }

  @media screen and (min-width: 769px) {
    .tasks-container-container {
      margin-top: 3rem;
      margin-left: 1vw;
      display: flex;
      justify-content: flex-start;
      gap: 2vw;
      flex-wrap: wrap;
      width: 78vw;
    }
  }
  // styling with grid, but problem is, with less than 5 Items, it is to tall
  /* background-color: red;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 2rem;
    grid-column-gap: 3rem; */
`;

export default Wrapper;
