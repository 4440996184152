import React from "react";
import { useSelector } from "react-redux";
import Wrapper from "../assets/wrappers/DiaryContainer.js";
import { DiaryRow } from "../components";

const DiaryContainer = ({ showSidebar }) => {
  const { yearList, monthList, searchValuesDiary, lastMonth } = useSelector(
    (store) => store.allDiary
  );

  let { month } = searchValuesDiary;
  return (
    <Wrapper>
      <div
        className={`diarycontainer-container ${!showSidebar && "hide-sidebar"}`}
      >
        <div className="banner">{month}</div>

        <DiaryRow key="Diary-Container-First-Month" />

        <div className="banner">{lastMonth}</div>

        <DiaryRow key="Diary-Container-Last-Month" lastMonth={lastMonth} />
      </div>
    </Wrapper>
  );
};

export default DiaryContainer;
