import styled from "styled-components/macro";

const Wrapper = styled.div`
  background: white;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  border-color: #b3e0ff;

  // selects the text-field of the the editor
  .ql-editor {
    min-height: 20rem;
    font-size: 1.1rem;
  }
`;
export default Wrapper;
