import styled from "styled-components/macro";

const Wrapper = styled.div`
  .diary-entry-container {
    margin: 0 auto;
    margin-top: 2rem;
    transition: 0.3s ease-in-out all;
    width: 70vw;
    border-radius: 3px;

    .form {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: white;
      padding: 1rem;
      border-style: solid;
      border-radius: 3px;
      border-width: 1px;
      background: white;
      border-color: #b3e0ff;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      width: 100%;

      .title-label {
        display: block;
        max-width: 6rem;
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
      }
      .title-input {
        border-radius: 5px;
        height: 2.3rem;
        padding: 0.25rem 0.5rem;
        width: 100%;
        font-size: 1.3rem;
        font-weight: 700;
        background-color: var(--primary-background);
        border-style: solid;
        border-color: var(--primary-blue-bright);
        border-width: 1px;
      }
      .rating-label {
        display: block;
        max-width: 6rem;
        margin-bottom: 0.5rem;
        font-size: 1rem;
      }
      .rating-input {
        border-radius: 5px;
        height: 2rem;
        padding: 0.25rem 0.5rem;
        width: 100%;
        font-size: 1rem;

        background-color: var(--primary-background);
        border-style: solid;
        border-color: var(--primary-blue-bright);
        border-width: 1px;
      }
    }

    .button-container {
      margin: 0 auto;

      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
      gap: 8vw;
      width: 100%;
    }

    .diary-entry-button {
      width: 16vw;
    }

    .save-button {
      background: var(--primary-green-40);

      :hover {
        background: var(--primary-green-30);
      }
    }
    .delete-button {
      background: var(--primary-red-60);
      :hover {
        background: var(--primary-red-40);
      }
    }
  }

  .hide-sidebar {
    width: 90vw;
    transition: 0.3s ease-in-out all;
  }
  @media screen and (min-width: 769px) {
    .diary-entry-container {
      width: 80vw;
      .form {
        margin: 0;
        width: 70vw;
      }
      .button-container {
        margin: 0;
        margin-top: 3rem;
        padding-left: 2rem;
        padding-right: 2rem;
        gap: 10rem;
        width: 70vw;
      }
    }

    .hide-sidebar {
      width: 80vw;
    }
  }
`;

export default Wrapper;
