import styled from "styled-components/macro";

const Wrapper = styled.div`
  .container {
    padding: 1rem;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    background: white;
    border-color: #b3e0ff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    .title {
      display: flex;
      justify-content: space-between;
    }
    .hide-button {
      justify-content: right;
      height: 2rem;
      margin: -0.5rem;
      font-weight: 600;
    }
    .form-container {
      display: flex;
      flex-wrap: wrap;
      gap: 3vw;
      max-width: 100rem;

      .form-label {
        font-size: 1.1rem;
      }
      .form-input {
        width: 16rem;
        transition: 0.3s ease-in-out all;
      }

      .form-select {
        width: 16rem;
        transition: 0.3s ease-in-out all;
      }
    }

    .button-container {
      display: flex;
      gap: 6rem;
      margin-top: 1rem;
    }

    .new-task-button {
    }

    .clear-filter-button {
      background-color: var(--primary-red-70);
    }
    .clear-filter-button:hover {
      background-color: var(--primary-red-60);
    }

    @media screen and (min-width: 769px) {
      margin: 0 auto;
      width: 78vw;
      padding: 1rem;

      .form-container {
        display: flex;
        flex-wrap: wrap;
        gap: 3vw;
        max-width: 100rem;

        .form-label {
          font-size: 1.1rem;
        }
        .form-input {
          width: 18rem;
        }

        .form-select {
          max-width: 9rem;
        }
      }

      .button-container {
        display: flex;
        gap: 17.5rem;
        margin-top: 1rem;
      }
    }
  }

  .hide-sidebar {
    .form-container {
      display: flex;
      flex-wrap: wrap;
      gap: 3vw;
      max-width: 100rem;

      .form-label {
        font-size: 1.1rem;
      }
      .form-input {
        width: 18rem;
        transition: 0.3s ease-in-out all;
      }

      .form-select {
        width: 18rem;
        transition: 0.3s ease-in-out all;
      }
    }
  }
`;

export default Wrapper;
