import React from "react";

import Wrapper from "../assets/wrappers/Logo";

import logo from "../assets/images/android-chrome-192x192.png";

const Logo = ({ width, height, logoWidth }) => {
  return (
    <Wrapper width={width} height={height} logoWidth={logoWidth}>
      <img src={logo} alt="logo" className="logo"></img>
      <p className="title">DNPTF</p>
    </Wrapper>
  );
};

export default Logo;
