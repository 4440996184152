import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import Wrapper from "../../assets/wrappers/DiaryEntry";
import {
  DiaryEntryEditor,
  FormRow,
  FormRowSelect,
  Loading,
  Alert,
} from "../../components";
import {
  useCreateDiaryMutation,
  useDeleteDiaryMutation,
  useGetDiaryQuery,
  useUpdateDiaryMutation,
} from "../../features/api/apiSlice";

// Attention mood and performance saved as string not number
const initialState = {
  diarytitle: "",

  //i need to have the text as initialValue as "<p><br></p>", or the resetting of the values will not work, seems to have connected to react quill
  text: "<p><br></p>",
  mood: "5",
  performance: "5",
};

const DiaryEntry = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const diaryId = searchParams.get("diaryId");

  console.log(diaryId);

  const { showSidebar, showAlert } = useSelector((store) => store.general);

  const [values, setValues] = useState(initialState);

  // the editor must deactived first, because otherwise it will overwrite the value of the title and performance and mood to initialState
  const [diaryEditor, setDiaryEditor] = useState(false);

  // this is necessary to work with reload while in diary editor

  const [oldDiaryFetched, setOldDiaryFetched] = useState(false);

  const [createDiary, { isLoading: createDiaryLoading }] =
    useCreateDiaryMutation();

  const [updateDiary, { isLoading: updateDiaryLoading }] =
    useUpdateDiaryMutation();

  const {
    data: diary,
    isLoading: getDiaryLoading,
    isFetching,
    isSuccess,
  } = useGetDiaryQuery(diaryId, { skip: diaryId === null });

  const [deleteDiary, { isLoading: deleteDiaryLoading }] =
    useDeleteDiaryMutation();

  let { diarytitle: diaryTitle, text, mood, performance } = values;

  let oldDiary;

  const onSaveClicked = async () => {
    try {
      if (diaryId !== null) {
        await updateDiary({
          diaryTitle,
          text,
          mood,
          performance,
          diaryId,
        }).unwrap();
        navigate(`/diary-read/?diaryId=${diaryId}`);
      }
      if (diaryId == null) {
        await createDiary({ diaryTitle, text, mood, performance })
          .unwrap()
          .then(setValues(initialState));
      }
    } catch (err) {
      console.err("Failed to save Diary", err);
    }
  };

  const onDeleteClicked = async () => {
    try {
      await deleteDiary(diaryId).unwrap();
      navigate("/diary");
    } catch (err) {
      console.err("Failed to delete Diary", err);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name.toLowerCase();
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  const handleTextChange = (textValue) => {
    // because of react quill i dont need to spread the values with ...values
    setValues({
      ...values,
      text: textValue,
    });
  };

  useEffect(() => {
    if (diary) {
      oldDiary = {
        diarytitle: diary.diary.diaryTitle,
        text: diary.diary.text,
        mood: diary.diary.mood,
        performance: diary.diary.performance,
      };

      // need to be in there, or the App broke, when i reload, because values have no value
      setValues(oldDiary);

      //need to be here to work with reload
      setOldDiaryFetched(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (oldDiaryFetched) {
      setDiaryEditor(true);
    }

    // for create new diary

    if (diaryId == null) {
      setDiaryEditor(true);
    }
  }, [oldDiaryFetched]);
  if (
    getDiaryLoading ||
    createDiaryLoading ||
    updateDiaryLoading ||
    deleteDiaryLoading ||
    isFetching
  ) {
    return <Loading center />;
  }

  // no ifSuccess otherwise the App broke, when i have no Diary loading in.
  if (isSuccess || diaryId === null)
    return (
      <Wrapper>
        <div
          className={`diary-entry-container ${!showSidebar && "hide-sidebar"}`}
        >
          {showAlert && <Alert />}
          <form className="form">
            <FormRow
              name="diaryTitle"
              value={diaryTitle}
              onChange={handleChange}
              type="text"
              labelText="Title"
              classLabel="title-label"
              classInput="title-input"
            />

            {diaryEditor && (
              <DiaryEntryEditor
                textValue={text}
                handleTextChange={handleTextChange}
              />
            )}

            <FormRowSelect
              name="mood"
              value={mood}
              onChange={handleChange}
              labelText="Mood"
              classLabel="rating-label"
              classInput="rating-input"
              list={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            />
            <FormRowSelect
              name="performance"
              value={performance}
              onChange={handleChange}
              labelText="Performance"
              classLabel="rating-label"
              classInput="rating-input"
              list={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            />
          </form>

          <div className="button-container">
            <button
              className="general-button save-button diary-entry-button"
              onClick={() => onSaveClicked()}
              type="submit"
            >
              Save Text
            </button>

            <button
              className="general-button diary-entry-button"
              onClick={() => {
                if (diaryId) {
                  navigate(`/diary-read/?diaryId=${diaryId}`);
                  return;
                }
                navigate("/diary/");
                return;
              }}
            >
              Get Back
            </button>
            <button
              className="general-button delete-button diary-entry-button"
              onClick={() => onDeleteClicked()}
            >
              Delete Diary Entry
            </button>
          </div>
        </div>
      </Wrapper>
    );
};

export default DiaryEntry;
