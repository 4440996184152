import React from "react";
import Wrapper from "../assets/wrappers/Landing";
import logo from "../assets/images/android-chrome-192x192.png";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <Wrapper>
      <header>
        {/* lass das logo mitskalieren */}

        <img className="header-logo" src={logo} alt="logo"></img>
        <p className="header-title">
          <b>D</b>o <b>n</b>ot <b>p</b>lan <b>t</b>o <b>f</b>ail
        </p>
      </header>
      <div className="landing-main">
        <h2>To-Do App</h2>
        <p>
          {" "}
          DNPTF ist just another To-Do App, with some extra functions, like
          daily schedule and weekly schedule, diary, timer- and analysis
          options.{" "}
        </p>
        <div className="landing-button-container">
          {/* link margin is not stylabe???? what is with width?*/}
          <Link to="/register" className={"landing-button"}>
            Login/Register
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default Landing;
