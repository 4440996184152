import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin: 0 auto;
  width: 80vw;

  .submit-button {
    margin-top: 3rem;
  }
`;

export default Wrapper;
