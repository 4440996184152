import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  padding: 1rem;
  width: 80vw;
  background: white;

  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  background: white;
  border-color: #b3e0ff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .button-container {
    margin-top: 2rem;
    max-width: 50rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .save-button {
      background: var(--primary-green-40);

      :hover {
        background: var(--primary-green-30);
      }
    }
    .decline-button {
    }
    .delete-button {
      background: var(--primary-red-60);
      :hover {
        background: var(--primary-red-40);
      }
    }
  }
`;

export default Wrapper;
