import React from "react";
import Wrapper from "../assets/wrappers/FormRowTags";
import CreatableSelect from "react-select/creatable";
import "../index.css";

const FormRowTags = ({
  type,
  name,
  value,
  labelText,
  onChange,
  tasksTags,
  className,
}) => {
  return (
    <Wrapper>
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
      <CreatableSelect
        isMulti
        options={tasksTags.map((tag) => ({ value: tag, label: tag }))}
        value={value && value}
        onChange={onChange}
        className={`form-input-tag-container ${className}`}
        classNamePrefix="form-input-tag"
      />
    </Wrapper>
  );
};

export default FormRowTags;
