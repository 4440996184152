import styled from "styled-components/macro";

const Wrapper = styled.div`
  width: 48vw;
  height: 16rem;
  .flip-card {
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    background: white;
    border-color: #b3e0ff;
    width: 48vw;
    height: 16rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow: hidden;
  }

  .click-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .open-container {
    max-width: 8vw;
    max-height: 8vw;

    margin-left: auto;

    background: green;
  }
  .open-container-img {
    object-fit: fill;
    max-width: 8vw;
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .back-enabled {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    padding: 1rem;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-back-text {
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-height: 10rem;
    overflow: hidden;
  }

  .continuation-dots {
    margin-top: -0.2rem;
    padding-left: 0.5rem;
    text-align: left;
  }
  .flip-card-front {
    color: black;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: 1.4rem;
  }

  .property {
    display: flex;
    flex-direction: row;
    font-size: 1.1rem;
  }

  .flip-card-back {
    background-color: white;
    color: black;
    transform: rotateY(180deg);
  }

  @media screen and (min-width: 769px) {
    width: 18vw;
    height: 16rem;

    .flip-card {
      width: 18vw;
      height: 16rem;
    }

    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      display: block;
    }
  }
`;

export default Wrapper;
