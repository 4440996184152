import { FaTasks } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { GoGraph } from "react-icons/go";
import { RiQuillPenLine } from "react-icons/ri";
import { ImBooks } from "react-icons/im";

const links = [
  { id: 1, text: "main", path: "/", icon: <AiOutlineHome /> },
  { id: 2, text: "tasks", path: "tasks", icon: <FaTasks /> },
  { id: 3, text: "analysis", path: "analysis", icon: <GoGraph /> },
  { id: 4, text: "diary", path: "diary", icon: <RiQuillPenLine /> },
  { id: 5, text: "reading", path: "reading", icon: <ImBooks /> },
];

export default links;
