import React from "react";
import { SingleTaskDisplay, Loading } from "./";
import Wrapper from "../assets/wrappers/TasksContainer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTasks, changePage } from "../features/allTasks/allTasksSlice.js";
import { getCurrentUser } from "../features/user/userSlice";

import { useGetAllTasksQuery } from "../features/api/apiSlice";

const TasksContainer = ({ isBigScreen }) => {
  const dispatch = useDispatch();
  const { searchValues, activePage } = useSelector((store) => store.allTasks);
  const { showSidebar } = useSelector((store) => store.general);
  const NumberOfTasksPerPage = () => {
    if (isBigScreen) {
      return 8;
    }
    return 6;
  };

  const {
    data: tasks,
    refetch,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetAllTasksQuery(searchValues);

  useEffect(() => {
    refetch();
    dispatch(changePage({ newPage: 1 }));
  }, [searchValues]);

  if (isLoading || isFetching) {
    return <Loading center />;
  }

  if (tasks.result.length <= 0) {
    return <h2>...no tasks</h2>;
  }
  if (isSuccess) {
    return (
      <Wrapper>
        <div
          className={`tasks-container-container ${
            !showSidebar && "hide-sidebar"
          }`}
        >
          {tasks.result
            .slice(
              (activePage - 1) * NumberOfTasksPerPage(),
              activePage * NumberOfTasksPerPage()
            )
            .map((task) => {
              const { taskName, category, deadline, tags, createdAt, _id } =
                task;
              return (
                <SingleTaskDisplay
                  key={_id}
                  taskId={_id}
                  category={category}
                  taskName={taskName}
                  deadline={deadline}
                  tags={tags}
                  createdAt={createdAt}
                />
              );
            })}
        </div>
      </Wrapper>
    );
  } else if (isError) {
    console.log("error in tasks");
    return <div>{error.toString()}</div>;
  }
};

export default TasksContainer;
