import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../assets/wrappers/SingleDiaryEntryDisplay";
import { FaRegCalendarAlt } from "react-icons/fa";
import { TbMoodNeutral } from "react-icons/tb";
import { AiOutlineStar } from "react-icons/ai";
import dayjs from "dayjs";
import { htmlToText } from "html-to-text";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";
import pageturner from "../assets/images/pageturner.png";

const SingleDiaryEntryDisplay = ({
  diaryId,
  title,
  createdAt,
  mood,
  performance,
  text,
}) => {
  const [checkOverflow, setCheckoverflow] = useState(null);
  const [backSide, setBackSide] = useState(false);
  const backCardTextRef = useRef(null);
  const isBigScreen = useMediaQuery({ query: `(min-width: 769px)` });

  function isOverflown(element) {
    return element.current.scrollHeight > element.current.clientHeight;
  }

  useEffect(() => {
    //ref needs to be in useeffect because its forbidden to read ref while rendering

    setCheckoverflow(isOverflown(backCardTextRef));
  }, []);

  if (isBigScreen) {
    return (
      <Wrapper>
        <Link to={`/diary-read/?diaryId=${diaryId}`} key={diaryId}>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <h3>{title || "Diary title"}</h3>
                <div className="properties">
                  <p className="property">
                    <span className="icon">
                      <FaRegCalendarAlt />
                    </span>
                    {dayjs(createdAt).format("DD/MM/YYYY") || "created At"}
                  </p>
                  <p className="property">
                    <span className="icon">
                      <TbMoodNeutral />
                    </span>
                    {mood}
                  </p>
                  <p className="property">
                    <span className="icon">
                      <AiOutlineStar />
                    </span>
                    {performance}
                  </p>
                </div>
              </div>
              <div className="flip-card-back">
                <div className="flip-card-back-text" ref={backCardTextRef}>
                  {parse(text.slice(0, 300))}
                </div>

                {checkOverflow && <p className="continuation-dots">...</p>}
              </div>
            </div>
          </div>
        </Link>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="flip-card">
        <div className="click-container" onClick={() => setBackSide(!backSide)}>
          <div className={`flip-card-inner ${backSide && "back-enabled"}`}>
            <div className={`flip-card-front`}>
              <h3>{title || "Diary title"}</h3>
              <div className="properties">
                <p className="property">
                  <span className="icon">
                    <FaRegCalendarAlt />
                  </span>
                  {dayjs(createdAt).format("DD/MM/YYYY") || "created At"}
                </p>
                <p className="property">
                  <span className="icon">
                    <TbMoodNeutral />
                  </span>
                  {mood}
                </p>
                <p className="property">
                  <span className="icon">
                    <AiOutlineStar />
                  </span>
                  {performance}
                </p>
              </div>
            </div>
            <div className={`flip-card-back`}>
              <div className="flip-card-back-text" ref={backCardTextRef}>
                {parse(text.slice(0, 300))}
              </div>

              {checkOverflow && <p className="continuation-dots">...</p>}
            </div>
          </div>
          <div className="open-container">
            <Link to={`/diary-read/?diaryId=${diaryId}`} key={diaryId}>
              <img
                className="open-container-img"
                src={pageturner}
                alt="pageturner"
              ></img>
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SingleDiaryEntryDisplay;
