import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import customFetch from "../../utils/axios";
import { apiSlice, useGetTaskQuery } from "../api/apiSlice";
import {
  addUserTagsToLocalStorage,
  removeUserTagsFromLocalStorage,
  getUserTagsFromLocalStorage,
} from "../../utils/localStorage";

const initialState = {
  pet: "kleinToby",
  isLoading: false,
  tasks: [],
  tasksTags: getUserTagsFromLocalStorage(),
  category: "",
  categoryList: ["Private", "Work", "Leisure time", "Startup", "Miscellaneous"],
  sortList: ["earliest", "latest", "A-Z", "Z-A"],
  searchValues: {
    search: "",
    category: "all",
    tags: "all",
    ending: "all",
    createdAt: "all",
    sort: "earliest",
  },

  //pages
  totalPages: 9,
  totalPagesMobile: 5,
  activePage: 1,

  // hide-button SearchForm:
  hideSearchForm: false,
};

const uniqueItemsArrayTags = (arr) => {
  let arrayOfItems = [];
  let array = arr.map((task) =>
    task.tags.map((element) => arrayOfItems.push(element))
  );
  let uniqueArrayOfItems = [...new Set(arrayOfItems.map((item) => item))];

  return uniqueArrayOfItems;
};

const defineTotalPages = (arr) => {
  let totalPages = Math.ceil(arr.length / 8);
  return totalPages;
};
const defineTotalPagesMobile = (arr) => {
  let totalPages = Math.ceil(arr.length / 6);
  return totalPages;
};

const allTasksSlice = createSlice({
  name: "allTasks",
  initialState,
  reducers: {
    handleSearchChange: (state, { payload: { name, value } }) => {
      state.searchValues = { ...state.searchValues, [name]: value };
    },
    handleSearchTags: (state, { payload: { value } }) => {
      state.searchValues = { ...state.searchValues, tags: value };
    },
    clearFilters: (state) => {
      state.searchValues = {
        search: "",
        category: "all",
        tags: "all",
        ending: "all",
        createdAt: "all",
        sort: "earliest",
      };
    },
    changePage: (state, { payload: { newPage } }) => {
      state.activePage = newPage;
    },
    hideSearchFormFunction: (state, { payload }) => {
      state.hideSearchForm = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAllTasks.matchFulfilled,
      (state, { payload }) => {
        state.tasks = payload.result;

        state.tasksTags = uniqueItemsArrayTags(payload.result);
        addUserTagsToLocalStorage(state.tasksTags);
        state.totalPages = defineTotalPages(state.tasks);
        state.totalPagesMobile = defineTotalPagesMobile(state.tasks);
      }
    );
  },

  // .addCase(getAllTasks.pending, (state) => {
  //   state.isLoading = true;
  // })

  // .addCase(getAllTasks.fulfilled, (state) => {
  //   state.isLoading = false;
  //   state.pet = "kleinToby is cute";
  // });
  // .addCase(getAllTasks.rejected, (state) => {
  //   state.isLoading = false;
  // });
  // },
  // });

  // export const getAllTasks = createAsyncThunk(
  //   "tasks/getAllTasks",
  //   async (allTasks, thunkAPI) => {
  //     try {
  //       const response = await customFetch.get("/tasks/");
  //       return response.data;
  //     } catch (error) {
  //       return thunkAPI.rejectWithValue(error.response.data.msg);
  //     }
  //   }
  // );
});

export const {
  handleSearchChange,
  handleSearchTags,
  clearFilters,
  changePage,
  hideSearchFormFunction,
} = allTasksSlice.actions;

export default allTasksSlice.reducer;
