import React from "react";
import {
  SingleTaskDisplay,
  SearchFormTasks,
  Loading,
  TasksContainer,
  PageBtnContainer,
} from "../../components";
import Wrapper from "../../assets/wrappers/Tasks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideSearchFormFunction } from "../../features/allTasks/allTasksSlice.js";
import { getCurrentUser } from "../../features/user/userSlice";
import { useGetAllTasksQuery } from "../../features/api/apiSlice";
import { useMediaQuery } from "react-responsive";

const Tasks = () => {
  const dispatch = useDispatch();
  const { hideSearchForm } = useSelector((store) => store.allTasks);
  const { showSidebar } = useSelector((store) => store.general);
  const isBigScreen = useMediaQuery({ query: `(min-width: 769px)` });

  return (
    <Wrapper>
      <div className={`tasks-container ${!showSidebar && "hide-Sidebar"}`}>
        {!hideSearchForm && <SearchFormTasks />}
        {hideSearchForm && (
          <button
            className="general-button show-button"
            onClick={() => dispatch(hideSearchFormFunction(false))}
          >
            Show Search Form
          </button>
        )}
        <TasksContainer isBigScreen={isBigScreen} />
        <PageBtnContainer isBigScreen={isBigScreen} />
      </div>
    </Wrapper>
  );
};

export default Tasks;
