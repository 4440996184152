import styled from "styled-components/macro";

const Wrapper = styled.aside`
  overflow: visible;
  height: 100%;

  .big-screen-logo {
    display: none;
  }
  .small-screen-logo {
    display: block;
  }
  .sidebar-container {
    display: block;
    width: 84px;
    border-right-style: solid;
    border-color: #cccccc;
    border-width: 1px;
    margin-left: -250px;
    height: 100%;
    min-height: 100vh;
    transition: 0.3s ease-in-out all;
  }
  .show-sidebar {
    margin-left: 0px;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin-top: 2rem;
  }

  .nav-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-transform: capitalize;
    font-size: 1.1rem;
    padding: 0.5rem;
    color: var(--primary-blue-link);
    transition: 0.3s ease-in-out all;

    :hover {
      color: var(--primary-blue-hover);
      background: var(--primary-blue-bright);
    }
  }

  .icon {
    margin-right: 0.5vw;
    font-size: 1.7rem;
  }

  .active {
    color: var(--primary-blue-active);
    font-weight: 530;
    padding-left: 0.8rem;
  }
  /* background: blue; */

  @media screen and (min-width: 769px) {
    .big-screen-logo {
      display: block;
    }
    .small-screen-logo {
      display: none;
    }
    .sidebar-container {
      width: 250px;
      min-height: 100vh;
    }
    .nav-link {
      flex-direction: row;
      font-size: 1.5rem;
      transition: 0.3s ease-in-out all;
      padding: 0.5rem;
      :hover {
        padding-left: 2rem;
      }
    }

    .active {
      color: var(--primary-blue-active);
      padding-left: 1.5rem;
      font-weight: 530;
    }
  }
`;

export default Wrapper;
