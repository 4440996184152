import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../assets/wrappers/DiaryRow.js";
import { useSelector } from "react-redux";
import { SingleDiaryEntryDisplay, Loading, CarouselWrapper } from "./index.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useGetAllDiaryQuery } from "../features/api/apiSlice.js";

//carousel
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const DiaryRow = ({ lastMonth }) => {
  let { searchValuesDiary } = useSelector((store) => store.allDiary);
  let [localSearchValuesDiary, setLocalSearchValuesDiary] =
    useState(searchValuesDiary);

  // for lastMonth

  const {
    data: diaries,
    refetch,
    isLoading,
    isSuccess,
    isFetching,
    isError,
    error,
  } = useGetAllDiaryQuery(localSearchValuesDiary);

  useEffect(() => {
    setLocalSearchValuesDiary({ ...searchValuesDiary });
    if (lastMonth) {
      setLocalSearchValuesDiary({ ...searchValuesDiary, month: lastMonth });
    }
    refetch();
  }, [searchValuesDiary, lastMonth]);

  if (isLoading || isFetching) {
    return <Loading center />;
  }

  if (isSuccess) {
    if (diaries.result.length <= 0) {
      return (
        <Wrapper>
          <div className="no-diaries-container">
            <h2>...no diary entries</h2>
          </div>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <div className="diary-container">
          <div className="this-month">
            {
              <Carousel
                key={lastMonth ? "Carousel-Last-Month" : "Carousel-First-Month"}
                className="carousel"
                responsive={responsive}
                showDots={true}
              >
                {diaries.result.map((diary) => {
                  const {
                    diaryTitle,
                    mood,
                    performance,
                    createdAt,
                    text,
                    _id: diaryId,
                  } = diary;
                  return (
                    <SingleDiaryEntryDisplay
                      key={diaryId}
                      diaryId={diaryId}
                      title={diaryTitle}
                      createdAt={createdAt}
                      mood={mood}
                      performance={performance}
                      text={text}
                    />
                  );
                })}
              </Carousel>
            }
          </div>
        </div>
      </Wrapper>
    );
  }
};

export default DiaryRow;
