import styled from "styled-components/macro";

const Wrapper = styled.main`
  height: 100%;
  max-width: 100vw;
  .dashboard {
    display: grid;
    grid-template-columns: auto 1fr;
  }
`;

export default Wrapper;
