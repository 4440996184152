import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import customFetch from "../../utils/axios";

import { removeUserTagsFromLocalStorage } from "../../utils/localStorage";

import generalSlice, { closeSidebar } from "../general/generalSlice";
import { apiSlice } from "../api/apiSlice";

const initialState = {
  userData: null,
  currentSection: "",
  rejected: false,
  logout: false,
  pet: "anton",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginTestUser: {
      reducer: (state) => {
        state.user = "marie2";
      },
    },
    currentSection: {
      reducer: (state, { payload: { section } }) => {
        state.currentSection = section;
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // // REGISTER

      // .addCase(registerUser.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(registerUser.fulfilled, (state, { payload }) => {
      //   const { userData } = payload;
      //   state.isLoading = false;
      //   state.userData = userData;
      // })
      // .addCase(registerUser.rejected, (state, { payload }) => {
      //   state.isLoading = false;
      // })

      // LOGIN
      // .addCase(loginUser.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(loginUser.fulfilled, (state, { payload }) => {
      //   const { userData } = payload;
      //   state.isLoading = false;
      //   state.userData = userData;
      //   state.rejected = false;
      // })
      // .addCase(loginUser.rejected, (state, { payload }) => {
      //   state.isLoading = false;
      // })

      // UPDATE USER

      // .addCase(updateUser.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(updateUser.fulfilled, (state, { payload }) => {
      //   const { userData } = payload;
      //   state.isLoading = false;
      //   state.userData = userData;
      // })
      // .addCase(updateUser.rejected, (state, { payload }) => {
      //   state.isLoading = false;
      //   state.rejected = true;
      // })

      //GET CURRENT USER
      // .addCase(getCurrentUser.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      //   const { userData } = payload;
      //   state.isLoading = false;
      //   state.userData = userData;
      //   state.rejected = false;
      // })
      // .addCase(getCurrentUser.rejected, (state, { payload }) => {
      //   state.isLoading = false;
      //   state.rejected = true;
      // })

      // //LOGOUT USER
      // .addCase(logoutUser.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(logoutUser.fulfilled, (state, { payload }) => {
      //   state.isLoading = false;
      //   state.userData = null;
      // })
      // .addCase(logoutUser.rejected, (state, { payload }) => {
      //   state.isLoading = false;
      // })

      //REGISTERUSER API
      .addMatcher(
        apiSlice.endpoints.registerUser.matchFulfilled,
        (state, { payload }) => {
          state.userData = payload.userData;
        }
      )
      //LOGINUSER API
      .addMatcher(
        apiSlice.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
          state.userData = payload.userData;
          state.pet = "anton 55";
        }
      )
      // UPDATEUSER API
      .addMatcher(
        apiSlice.endpoints.updateUser.matchFulfilled,
        (state, { payload }) => {
          state.userData = payload.userData;
          state.pet = "kleintoby is updated";
        }
      )
      //LOGOUTUSER API
      .addMatcher(
        apiSlice.endpoints.logoutUser.matchFulfilled,
        (state, { payload }) => {
          state.userData = null;
          removeUserTagsFromLocalStorage();
          state.pet = "marie";
        }
      )
      .addMatcher(apiSlice.endpoints.logoutUser.matchPending, (state) => {
        state.logout = true;
        removeUserTagsFromLocalStorage();
        state.pet = "tobias";
      })
      // GETCURRENTUSER API
      .addMatcher(
        apiSlice.endpoints.getCurrentUser.matchFulfilled,
        (state, { payload }) => {
          const { userData } = payload;
          state.userData = userData;
          state.logout = false;
        }
      )
      .addMatcher(apiSlice.endpoints.getCurrentUser.matchRejected, (state) => {
        state.userData = null;
        state.logout = false;
      });
  },
});

// export const registerUser = createAsyncThunk(
//   "user/registerUser",
//   async (user, thunkAPI) => {
//     return registerUserThunk("/auth/register", user, thunkAPI);
//   }
// );

// export const loginUser = createAsyncThunk(
//   "user/loginUser",
//   async (user, thunkAPI) => {
//     return loginUserThunk("/auth/login", user, thunkAPI);
//   }
// );

// export const updateUser = createAsyncThunk(
//   "user/updateUser",
//   async (user, thunkAPI) => {
//     return updateUserThunk("/auth/updateUser", user, thunkAPI);
//   }
// );

// export const logoutUser = createAsyncThunk(
//   "user/logoutUser",
//   async (user, thunkAPI) => {
//     return logoutUserThunk("/auth/logoutUser", user, thunkAPI);
//   }
// );

// export const getCurrentUser = createAsyncThunk(
//   "user/getCurrentUser",
//   async (user, thunkAPI) => {
//     return getCurrentUserThunk("/auth/getCurrentUser", user, thunkAPI);
//   }
// );

export const testUser = createAsyncThunk(
  "user/testUser",
  async (user, thunkAPI) => {
    // with getState not working
    // const state = thunkAPI.getState();
    // state.user = "marie";
    // with Reducers
    thunkAPI.dispatch(userSlice.actions.loginTestUser());
  }
);

export const { loginTestUser, currentSection } = userSlice.actions;

export default userSlice.reducer;
