import React from "react";
import { useSelector } from "react-redux";
import Wrapper from "../assets/wrappers/Alert.js";

const Alert = () => {
  const { alertText, alertType } = useSelector((store) => store.general);

  return (
    <Wrapper>
      <div className={`alert alert-${alertType}`}>{alertText}</div>
    </Wrapper>
  );
};

export default Alert;
