import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changePage,
  defineTotalPages,
} from "../features/allTasks/allTasksSlice.js";
import Wrapper from "../assets/wrappers/PageBtnContainer.js";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const PageBtnContainer = ({ isBigScreen }) => {
  const dispatch = useDispatch();

  const { totalPages, totalPagesMobile, activePage } = useSelector(
    (store) => store.allTasks
  );

  const pages = Array.from(
    { length: isBigScreen ? totalPages : totalPagesMobile },
    (_, index) => {
      return index + 1;
    }
  );

  const choosePage = (newPage) => {
    dispatch(changePage({ newPage }));
  };

  const nextPage = () => {
    let newPage = activePage + 1;
    if (newPage > pages.length) {
      newPage = 1;
    }
    dispatch(changePage({ newPage }));
  };
  const prevPage = () => {
    let newPage = activePage - 1;
    if (newPage < 1) {
      newPage = pages.length;
    }
    dispatch(changePage({ newPage }));
  };

  return (
    <Wrapper>
      <div className="page-btn-container">
        <button className="page-btn icon-btn-left" onClick={() => prevPage()}>
          <IoChevronBack className="icon" />
        </button>

        <div className="page-btn-page-Numbers">
          {pages.map((page) => {
            return (
              <button
                key={page}
                className={`page-btn ${activePage === page && "active-page"}`}
                onClick={() => choosePage(page)}
              >
                {page}
              </button>
            );
          })}
        </div>

        <button className="page-btn icon-btn-right" onClick={() => nextPage()}>
          <IoChevronForward className="icon" />
        </button>
      </div>
    </Wrapper>
  );
};

export default PageBtnContainer;
