import React from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import Wrapper from "../../assets/wrappers/DiaryRead.js";
import { Loading } from "../../components";
import parse from "html-react-parser";
import { TbMoodNeutral } from "react-icons/tb";
import { AiOutlineStar } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";

import {
  useGetDiaryQuery,
  useDeleteDiaryMutation,
} from "../../features/api/apiSlice.js";

const DiaryRead = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const diaryId = searchParams.get("diaryId");

  const {
    data: diary,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetDiaryQuery(diaryId);

  const [deleteDiary, { isLoading: deleteDiaryLoading }] =
    useDeleteDiaryMutation();

  const onDeleteClicked = async () => {
    try {
      await deleteDiary(diaryId).unwrap();
      navigate("/diary");
    } catch (err) {
      console.err("Failed to delete Diary", err);
    }
  };

  if (isLoading || isFetching || deleteDiaryLoading) {
    <Loading center />;
  }

  if (isSuccess)
    return (
      <Wrapper>
        <h2>{diary.diary.diaryTitle || "Title of Diary-Entry"}</h2>
        <div className="properties">
          <div className="property">
            <span className="icon">
              <FaRegCalendarAlt />
            </span>
            {dayjs(diary.diary.createdAt).format("DD/MM/YYYY") || "created At"}
          </div>
          <div className="property">
            <span className="icon">
              <TbMoodNeutral />
            </span>
            {diary.diary.mood}
          </div>
          <div className="property">
            <span className="icon">
              <AiOutlineStar />
            </span>
            {diary.diary.performance}
          </div>
        </div>
        <div className="diary-text">
          {parse(diary.diary.text)} <br></br>
        </div>
        <div className="button-group">
          <Link to={`/diary-entry/?diaryId=${diaryId}`}>
            <button className="general-button">Edit</button>
          </Link>
          <button
            className="general-button"
            onClick={() => {
              navigate("/diary/");
            }}
          >
            Get Back
          </button>
          <button
            className="general-button delete-button"
            onClick={() => onDeleteClicked()}
          >
            Delete
          </button>
        </div>
      </Wrapper>
    );
};

export default DiaryRead;
