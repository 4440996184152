import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../assets/wrappers/SearchFormTasks";
import { FormRow, FormRowSelect, FormRowTags, Loading } from ".";
import { currentSection } from "../features/user/userSlice";
import {
  handleSearchChange,
  handleSearchTags,
  clearFilters,
  hideSearchFormFunction,
} from "../features/allTasks/allTasksSlice";

const SearchFormTasks = () => {
  const { categoryList, sortList, tasksTags, searchValues } = useSelector(
    (store) => store.allTasks
  );
  const { showSidebar } = useSelector((store) => store.general);
  const [localSearchValues, setLocalSearchValues] = useState(searchValues);
  let { search, category, tags, ending, sort } = localSearchValues;
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    dispatch(
      handleSearchChange({ name: e.target.name, value: e.target.value })
    );
  };

  const handleTagsChange = (selectedOptions) => {
    dispatch(handleSearchTags({ value: selectedOptions }));
  };

  useEffect(() => {
    setLocalSearchValues(searchValues);
  }, [searchValues, localSearchValues]);

  return (
    <Wrapper>
      <div className={`container ${!showSidebar && "hide-sidebar"}`}>
        <div className="title">
          <h2>Search Form</h2>
          <button
            className="general-button hide-button"
            onClick={() => dispatch(hideSearchFormFunction(true))}
          >
            X
          </button>
        </div>

        <form className="form-container">
          <FormRow
            type="text"
            name="search"
            labelText="Search"
            value={search}
            onChange={handleSearch}
          />
          <FormRowSelect
            labelText="Category"
            name="category"
            value={category}
            list={["all", ...categoryList]}
            onChange={handleSearch}
          />

          <FormRowTags
            className={`form-input-tag-container-searchForm ${
              !showSidebar && "form-input-tag-container-searchForm-hide-search"
            }`}
            tasksTags={tasksTags}
            labelText="Tags"
            value={tags}
            onChange={handleTagsChange}
          />

          <FormRowSelect
            labelText="Ending in"
            name="ending"
            value={ending}
            list={["all", "1d", "10d", "30d"]}
            onChange={handleSearch}
          />

          <FormRowSelect
            labelText="Sort"
            name="sort"
            value={sort}
            list={sortList}
            onChange={handleSearch}
          />
        </form>
        <div className="button-container">
          <Link to="/task">
            <button
              className="general-button new-task-button"
              onClick={() => dispatch(currentSection({ section: "task" }))}
            >
              New Task
            </button>
          </Link>
          <button
            className="general-button clear-filter-button"
            onClick={() => dispatch(clearFilters())}
          >
            Clear Filters
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default SearchFormTasks;
