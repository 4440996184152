import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin: 0 auto;
  width: 80vw;
  padding: 1rem;

  .properties {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }

  .property {
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: 1.4rem;
  }

  .diary-text {
    padding: 0.5rem;
    background: white;
    border-radius: 10px;
    margin-bottom: 2vh;
    width: 70vw;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    width: 70vw;
  }

  .save-button {
    background: var(--primary-green-40);

    :hover {
      background: var(--primary-green-30);
    }
  }
  .delete-button {
    background: var(--primary-red-60);
    :hover {
      background: var(--primary-red-40);
    }
  }

  @media screen and (min-width: 769px) {
    width: 60vw;

    .properties {
      flex-direction: row;
      gap: 3rem;
      height: none;
    }

    .diary-text {
      width: 60vw;
    }

    .button-group {
      width: 60vw;
    }
  }
`;
export default Wrapper;
