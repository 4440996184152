import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Wrapper from "../../assets/wrappers/Task";
import {
  FormRow,
  FormRowSelect,
  FormRowTags,
  Loading,
  Alert,
} from "../../components/";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  useCreateTaskMutation,
  useGetTaskQuery,
  useUpdateTaskMutation,
  useGetAllTasksQuery,
  useDeleteTaskMutation,
} from "../../features/api/apiSlice";

const initialState = {
  taskname: "",
  deadline: "",
  category: "",
  tags: "",
};

const Task = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const { showAlert } = useSelector((store) => store.general);

  const taskId = searchParams.get("taskId");
  const [values, setValues] = useState(initialState);

  const { categoryList, tasksTags } = useSelector((store) => store.allTasks);

  const [createTask, { isLoading: createTaskLoading }] =
    useCreateTaskMutation();

  const [updateTask, { isLoading: updateTaskLoading }] =
    useUpdateTaskMutation();

  const {
    data: task,
    isLoading: getTaskLoading,
    isFetching,
    isSuccess,
  } = useGetTaskQuery(taskId, { skip: taskId === null });

  const [deleteTask, { isLoading: deleteTaskLoading }] =
    useDeleteTaskMutation();

  // i need to search for all tasks, to get all Tags in the createTask page, even i have filters in the search form
  // this query triggers the extrareducer in allTasksSlice
  const { data: tasks, isLoading: getAllTasksLoading } = useGetAllTasksQuery({
    category: "all",
    tags: "all",
    ending: "all",
    createdAt: "all",
  });

  let oldTask;
  let { taskname: taskName, deadline, category, tags } = values;

  const onSaveClicked = async () => {
    try {
      if (taskId !== null) {
        await updateTask({ taskName, deadline, category, tags, taskId });
        navigate(`/task/?taskId=${taskId}`);
      }
      if (taskId == null) {
        await createTask({ taskName, deadline, category, tags }).unwrap();
        setValues(initialState);
      }
    } catch (err) {
      console.error("Failed to save task:", err);
    }
  };

  const onDeleteClicked = async () => {
    try {
      await deleteTask(taskId).unwrap();
      navigate("/tasks/");
    } catch (err) {
      console.error("Failed to delete the post: ", err);
    }
  };
  const handleChange = (e) => {
    const name = e.target.name.toLowerCase();
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit entered");
  };

  const handleTagsChange = (selectedOptions) => {
    console.log("handleTagsChange", selectedOptions);

    setValues({ ...values, tags: selectedOptions });
  };
  let currentTaskName;

  // oldTask need to be in UseEffect, because otherwise it loads not fast enough and initialstate is set as values
  useEffect(() => {
    if (task) {
      oldTask = {
        taskname: task.task.taskName,
        deadline: task.task.deadline.substr(0, 10),
        category: task.task.category,
        tags: task.task.tags.map((tag) => ({ value: tag, label: tag })),
      };
      setValues(oldTask);
    }
  }, [isSuccess]);

  if (
    createTaskLoading ||
    getTaskLoading ||
    isFetching ||
    updateTaskLoading ||
    getAllTasksLoading ||
    deleteTaskLoading
  ) {
    return <Loading center />;
  }

  // no ifSuccess otherwise the App broke, when i have no task loading in.
  return (
    <Wrapper>
      {showAlert && <Alert />}
      <h2>{(task && task.task.taskName) || ""}</h2>

      <form
        className="form-container"
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <FormRow
          name="taskName"
          type="text"
          labelText="Task Name"
          onChange={handleChange}
          value={taskName}
        />
        <FormRow
          name="deadline"
          type="date"
          labelText="Deadline"
          onChange={handleChange}
          value={deadline}
        />
        <FormRowSelect
          type="select"
          name="category"
          labelText="Category"
          list={["", ...categoryList]}
          onChange={handleChange}
          value={category}
        />

        {/* <CreatableSelect
          isMulti
          options={tasksTags.map((tag) => ({ value: tag, label: tag }))}
          name="tags"
          type="text"
          labelText="Tags"
          onChange={handleChange}
          value={tags}
        /> */}
        <FormRowTags
          tasksTags={tasksTags}
          labelText="Tags"
          value={tags}
          onChange={handleTagsChange}
        />
        <div className="button-container">
          <button
            className="general-button save-button"
            onClick={() => onSaveClicked()}
            type="submit"
          >
            Save
          </button>
          <button
            className="general-button decline-button"
            onClick={() => {
              navigate("/tasks/");
            }}
          >
            Back
          </button>
          <button
            className="general-button delete-button"
            onClick={() => onDeleteClicked()}
          >
            Delete
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default Task;
