import { Routes, Route, BrowserRouter } from "react-router-dom";

import {
  Landing,
  Error,
  Register,
  ProtectedRoute,
  SharedLayout,
  AlphaProtection,
  Verify,
  ResetPassword,
} from "./pages";

import {
  Diary,
  Analysis,
  MainPage,
  Reading,
  Tasks,
  Settings,
  Task,
  DiaryEntry,
  DiaryRead,
} from "./pages/dashboard";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<MainPage />} />
          <Route index path="Diary" element={<Diary />} />
          <Route index path="Diary-entry" element={<DiaryEntry />} />
          <Route index path="Diary-entry:id" element={<DiaryEntry />} />
          <Route index path="Analysis" element={<Analysis />} />
          <Route index path="Reading" element={<Reading />} />
          <Route index path="Tasks" element={<Tasks />} />
          <Route index path="Settings" element={<Settings />} />
          <Route index path="Task" element={<Task />} />
          <Route index path="Task/:id" element={<Task />} />
          <Route index path="Diary-Read" element={<DiaryRead />} />
          <Route index path="Diary-Read/:id" element={<DiaryRead />} />
        </Route>

        <Route path="/landing" element={<Landing />} />
        <Route path="/user/verify-email/:token" element={<Verify />} />
        <Route path="/register" element={<Register />} />
        <Route path="user/reset-password/:token" element={<ResetPassword />} />
        <Route path="user/forgot-password" element={<ForgotPassword />} />

        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
