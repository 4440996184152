import styled from "styled-components/macro";

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  height: ${({ height }) => height};
  width: ${({ width }) => width || "auto"};

  .logo {
    width: ${({ logoWidth }) => logoWidth || "5rem"};
    object-fit: scale-down;
  }
  .title {
    display: none;
    visibility: hidden;
  }
  @media screen and (min-width: 769px) {
    .title {
      display: block;
      visibility: visible;
      margin-left: 1.5rem;
      align-self: center;
      font-weight: bold;
      font-size: 2.5rem;
      color: var(--primary-violett);
    }
  }
`;

export default Wrapper;
