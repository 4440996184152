import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.dnptf.com/api/v1",

    credentials: "include",
  }),
  tagTypes: ["Tasks", "Diary", "Logout"],

  endpoints: (builder) => ({
    // USER API
    registerUser: builder.mutation({
      query: (initialUser) => ({
        url: "/auth/register",
        method: "POST",
        body: initialUser,
      }),
    }),
    loginUser: builder.mutation({
      query: (initialUser) => ({
        url: "/auth/login",
        method: "POST",
        body: initialUser,
      }),
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: "/auth/updateUser",
        method: "PATCH",
        body: user,
      }),
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: "/auth/logoutUser",
        method: "GET",
      }),
    }),
    getCurrentUser: builder.query({
      query: () => "/auth/getCurrentUser",
    }),
    verifyEmail: builder.mutation({
      query: (data) => ({
        url: "/auth/verify-email",
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: data,
      }),
    }),

    // TASK API
    getAllTasks: builder.query({
      query: (searchValues) => {
        let { category, tags, ending, sort, search } = searchValues;

        //have to convert the tags from "react-select-form" to a "normal-array-format"

        if (Array.isArray(tags)) {
          tags = tags.map((tags) => tags.value);

          // i need to change the structure, because if just pass an array into the url the result is : tag1, tag2 and comma in url seems to be bad. Amazon uses a | for the separation
          tags = tags.join("|");
        }
        let url = `/tasks?category=${category}&tags=${tags}&ending=${ending}&sort=${sort}`;

        if (search) {
          url = url + `&search=${search}`;
        }
        return url;
      },
      providesTags: ["Tasks"],
    }),
    getTask: builder.query({
      query: (taskId) => `/tasks/${taskId}`,
      providesTags: ["Tasks"],
    }),
    createTask: builder.mutation({
      query: (initialTask) => ({
        url: "/tasks/",
        method: "POST",
        body: initialTask,
      }),
      invalidatesTags: ["Tasks"],
    }),
    updateTask: builder.mutation({
      query: (initialTask) => ({
        url: `/tasks/${initialTask.taskId}`,
        method: "PATCH",
        body: initialTask,
      }),
      invalidatesTags: ["Tasks"],
    }),
    deleteTask: builder.mutation({
      query: (taskId) => ({
        url: `/tasks/${taskId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tasks"],
    }),

    //DIARY API

    createDiary: builder.mutation({
      query: (initialDiary) => ({
        url: "/diary/",
        method: "POST",
        body: initialDiary,
      }),
      invalidatesTags: ["Diary"],
    }),
    getDiary: builder.query({
      query: (diaryId) => `/diary/${diaryId}`,
      method: "GET",
      providesTags: ["Diary"],
    }),
    getAllDiary: builder.query({
      query: (searchValuesDiary) => {
        let { year, month } = searchValuesDiary;

        let url = `/diary?year=${year}&month=${month}`;
        return url;
      },
      providesTags: ["Diary"],
    }),
    updateDiary: builder.mutation({
      query: (initialDiary) => ({
        url: `/diary/${initialDiary.diaryId}`,
        method: "PATCH",
        body: initialDiary,
      }),
      invalidatesTags: ["Diary"],
    }),
    deleteDiary: builder.mutation({
      query: (diaryId) => ({
        url: `/diary/${diaryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Diary"],
    }),
  }),
});

export const {
  useGetAllTasksQuery,
  useCreateTaskMutation,
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useUpdateUserMutation,
  useGetCurrentUserQuery,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useDeleteTaskMutation,
  useGetTaskQuery,
  useUpdateTaskMutation,
  useCreateDiaryMutation,
  useGetAllDiaryQuery,
  useGetDiaryQuery,
  useUpdateDiaryMutation,
  useDeleteDiaryMutation,
} = apiSlice;
