import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { logoutUser } from "../user/userSlice";
import { apiSlice } from "../api/apiSlice";

export const initialState = {
  // AlertState
  showAlert: false,
  alertText: "",
  alertType: "",
  testState: "kleinToby",

  //Sidebar
  showSidebar: false,

  //general Loading(Fetching):
  isLoading: false,
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    displayAlert: {
      reducer: (state, { payload: { alertText, alertType } }) => {
        state.showAlert = true;
        state.alertText = alertText;
        state.alertType = alertType;
      },
    },
    hideAlert: {
      reducer: (state) => {
        state.showAlert = false;
        state.alertText = "";
        state.alertType = "";
      },
    },
    toggleSidebar: {
      reducer: (state) => {
        state.showSidebar = !state.showSidebar;
      },
    },
    closeSidebar: {
      reducer: (state) => {
        state.showSidebar = false;
      },
    },
    isLoadingTrue: {
      reducer: (state) => {
        state.isLoading = true;
      },
    },
    isLoadingFalse: {
      reducer: (state) => {
        state.isLoading = false;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      //ALERTS
      .addMatcher(
        apiSlice.endpoints.loginUser.matchRejected,
        (state, { payload }) => {
          state.showAlert = true;
          state.alertText = payload.data.msg;
          state.alertType = "fail";
        }
      )

      .addMatcher(
        apiSlice.endpoints.registerUser.matchRejected,
        (state, { payload }) => {
          state.showAlert = true;
          state.alertText = payload.data.msg;
          state.alertType = "fail";
        }
      )

      .addMatcher(
        apiSlice.endpoints.updateUser.matchRejected,
        (state, { payload }) => {
          state.showAlert = true;
          state.alertText = payload.data.msg;
          state.alertType = "fail";
        }
      )

      .addMatcher(
        apiSlice.endpoints.createTask.matchRejected,
        (state, { payload }) => {
          state.showAlert = true;
          state.alertText = payload.data.msg;
          state.alertType = "fail";
        }
      )

      .addMatcher(
        apiSlice.endpoints.updateTask.matchRejected,
        (state, { payload }) => {
          state.showAlert = true;
          state.alertText = payload.data.msg;
          state.alertType = "fail";
        }
      )

      .addMatcher(
        apiSlice.endpoints.createDiary.matchRejected,
        (state, { payload }) => {
          state.showAlert = true;
          state.alertText = payload.data.msg;
          state.alertType = "fail";
        }
      )

      .addMatcher(
        apiSlice.endpoints.updateDiary.matchRejected,
        (state, { payload }) => {
          state.showAlert = true;
          state.alertText = payload.data.msg;
          state.alertType = "fail";
        }
      )
      // LOGOUT API
      .addMatcher(apiSlice.endpoints.logoutUser.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(apiSlice.endpoints.logoutUser.matchFulfilled, (state) => {
        state.isLoading = false;
      })
      .addMatcher(apiSlice.endpoints.logoutUser.matchRejected, (state) => {
        state.isLoading = false;
      });
  },
});

// export const closeSidebar = () => {
//   return;
// };

export const {
  displayAlert,
  hideAlert,
  toggleSidebar,
  closeSidebar,
  isLoadingTrue,
  isLoadingFalse,
} = generalSlice.actions;

export default generalSlice.reducer;
