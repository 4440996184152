import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import { getCurrentUser } from "../features/user/userSlice";
import { useGetCurrentUserQuery, apiSlice } from "../features/api/apiSlice";

import React from "react";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const { isLoading } = useSelector((store) => store.general);
  const {
    data: userData,
    isLoading: userLoading,
    refetch,
    isSuccess,
    isError,
    error,

    // need dummyID or skip is not working
  } = useGetCurrentUserQuery("dummyID", { skip: user.logout === true });

  //!userLoading so the app has time when you refresh the page, to get the userData

  if (isLoading || user.logout) {
    return <Loading />;
  }
  if (userLoading) {
    return <Loading center />;
  }
  if (!userLoading && user.userData === null && !user.logout) {
    console.log("return to landing in protected route");
    return <Navigate to="/landing" />;
  }

  if (isSuccess) {
    return children;
  }

  // !user.userData === null, da er sonst schon oft beim login error gibt, da der getCurrentUserQuery jedesmal neu lädt, ein error gibt und diesen im RTK-Query-Cache speichert
  if (!user.userData === null && isError) {
    console.log(error);
    console.log("error in protected route");
    return <Navigate to="/landing" />;
  }
};

export default ProtectedRoute;
