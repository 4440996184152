import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Wrapper from "../assets/wrappers/Register";
import { Logo, FormRow, Alert, Loading } from "../components";
import { registerUser, loginUser, testUser } from "../features/user/userSlice";
import {
  useRegisterUserMutation,
  useLoginUserMutation,
} from "../features/api/apiSlice";
import { displayAlert, hideAlert } from "../features/general/generalSlice";
import { Navigate, useNavigate, Link } from "react-router-dom";

// import redux toolkit video 524 und 482

const initialState = {
  name: "",
  email: "",
  password: "",
  isMember: false,
};

const Register = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { userData } = useSelector((store) => store.user);
  const { showAlert, alertText, alertType } = useSelector(
    (store) => store.general
  );
  const [registered, setRegistered] = useState(false);

  const { name, email, password, isMember } = values;
  const dispatch = useDispatch();

  // USER API

  const [
    registerUser,
    { isLoading: registerLoading, isSuccess: registerSuccess },
  ] = useRegisterUserMutation();
  const [loginUser, { isLoading: loginLoading, isSuccess: loginSuccess }] =
    useLoginUserMutation();

  const canSaveRegister =
    [name, email, password].every(Boolean) && !registerLoading;

  const canSaveLogin = [email, password].every(Boolean) && !loginLoading;

  const onSaveClicked = async () => {
    console.log("hey, on save clicked");
    if (!isMember && canSaveRegister) {
      try {
        await registerUser({ name, email, password }).unwrap();
        // navigate would be double because of the isSuccess return further down
        // navigate("/");
        setRegistered(true);
      } catch (err) {
        console.error("Failed to register", err);
      }
    }

    if (isMember && canSaveLogin) {
      try {
        await loginUser({ email, password }).unwrap();
        // navigate would be double because of the isSuccess return further down
        // navigate("/");
      } catch (err) {
        console.error("Failed to login", err);
      }
    }
  };

  const handleChange = (e) => {
    const name = e.target.name.toLowerCase();
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  const toggleRegister = () => {
    setValues({ ...values, isMember: !values.isMember });
    dispatch(hideAlert());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isMember) {
      if (!email || !password) {
        dispatch(
          displayAlert({
            alertText: "Please provide all values",
            alertType: "fail",
          })
        );
      }

      if (email && password) {
        onSaveClicked();
      }
    }

    if (!name || !email || !password) {
      dispatch(
        displayAlert({
          alertText: "Please provide all values",
          alertType: "fail",
        })
      );
    }

    if (name && email && password) {
      onSaveClicked();
    }
  };
  //ALERT TESTING
  // const showAlertTest = () => {
  //   console.log("alert route resting");
  //   dispatch(
  //     displayAlert({ alertText: "test alert for Toby", alertType: "fail" })
  //   );
  // };

  // const hideAlertTest = () => {
  //   dispatch(hideAlert());
  // };

  // useEffect(() => {
  //   if (userData) {
  //     navigate("/");
  //   }
  // }, [userData, navigate]);

  useEffect(() => {}, [showAlert]);
  if (registerLoading || loginLoading) {
    return <Loading center />;
  }

  if (loginSuccess) {
    return <Navigate to="/" />;
  }

  if (registerSuccess && registered) {
    return (
      <Wrapper>
        <header>
          <Logo className="logo-register" />
        </header>

        <div>
          Please verify your Email! If not available, please check also in the
          spam folder!
          <button
            className={"general-button register-button"}
            type="button"
            onClick={() => {
              setRegistered(false);
            }}
          >
            To Login Page
          </button>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <header>
        <Logo />
      </header>

      <form onSubmit={onSubmit}>
        <p className="title">{values.isMember ? "Login" : "Register"}</p>

        {/* ALERT TESTING */}
        {/* {showAlert && <Alert />}
        <button type="button" onClick={showAlertTest}>
          show alert test
        </button>
        <button type="button" onClick={hideAlertTest}>
          hide alert test
        </button> */}

        {showAlert && <Alert />}
        {!values.isMember && (
          <div className="form-container">
            <FormRow
              type={"text"}
              name={"Name"}
              onChange={handleChange}
            ></FormRow>
          </div>
        )}
        <div className="form-container">
          <FormRow
            type={"email"}
            name={"Email"}
            onChange={handleChange}
          ></FormRow>
        </div>
        <div className="form-container">
          <FormRow
            type={"password"}
            name={"Password"}
            onChange={handleChange}
          ></FormRow>
        </div>
        <button
          className="register-button general-button"
          type="submit"
          // onClick={() => onSaveClicked()}
        >
          Submit
        </button>

        <section className="toggle-section">
          <p>{values.isMember ? "Not a member yet?" : "Already a member?"}</p>
          <button
            className="toggle-button"
            type="button"
            onClick={() => toggleRegister()}
          >
            {values.isMember ? "Register" : "Login"}
          </button>
        </section>
        <section className="toggle-section">
          <p>Forgot Password?</p>
          <button
            className="toggle-button"
            type="button"
            onClick={() => navigate("/user/forgot-password")}
          >
            Forgot Password
          </button>
        </section>
      </form>
    </Wrapper>
  );
};

export default Register;
