import styled from "styled-components/macro";

const Wrapper = styled.section`
  .button-container {
    display: flex;
    flex-direction: column;
  }
  .button {
    border-style: none;
    color: white;
    font-size: 1.3rem;
    width: 8rem;
    background: var(--primary-blue-75);
    padding: 0.2rem;
  }
  .button:hover {
    background: var(--primary-blue-65);
  }

  .last-button {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media screen and (min-width: 769px) {
    .button {
      width: 12rem;
    }
  }
`;

export default Wrapper;
