import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin-top: 2rem;
  .page-btn-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 0.4rem;

    align-items: center;
    height: 8rem;
  }
  .page-btn {
    font-weight: bold;
    color: var(--primary-blue-link);
    padding: 0.5rem;
    background: #99ebff;
    border-style: none;
    border-radius: 3px;
  }
  .page-btn:hover {
    background: #00b8e6;
    cursor: pointer;
  }

  .page-btn-page-Numbers {
    display: flex;

    flex-wrap: wrap;
    gap: 0.4rem;

    align-items: center;
    height: 6rem;
    max-width: 50vw;
  }
  .active-page {
    background: #00ccff;
    padding: 0.7rem;
  }
  .icon {
    font-size: 1.5rem;
  }

  .icon-btn-left {
    margin-right: 1rem;
    padding: 0.2rem;
  }
  .icon-btn-right {
    margin-left: 1rem;
    padding: 0.2rem;
  }
`;

export default Wrapper;
