import React from "react";
import Wrapper from "../assets/wrappers/SingleTaskDisplay";
import { Link } from "react-router-dom";
import { currentSection } from "../features/user/userSlice";
import { useDispatch } from "react-redux";
import { IoMdTime } from "react-icons/io";
import { BsHourglassSplit } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineTag } from "react-icons/ai";
import { BiCategoryAlt } from "react-icons/bi";
import { useDeleteTaskMutation } from "../features/api/apiSlice";
import { Loading } from "../components/index.js";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const SingleTaskDisplay = ({
  taskName,
  category,
  deadline,
  tags,
  createdAt,
  taskId,
}) => {
  const dispatch = useDispatch();
  const [deleteTask, { isLoading }] = useDeleteTaskMutation();

  // const canSave = [taskId].every(Boolean) && !isLoading;

  const onDeleteClicked = async () => {
    try {
      await deleteTask(taskId).unwrap();
    } catch (err) {
      console.error("Failed to delete the post: ", err);
    }
  };

  const addingOneDay = (date) => {
    const a = dayjs(deadline);
    const newDate = a.add(1, "day");

    return newDate;
  };
  const distanceToToday = (date) => {
    let today = dayjs();
    let differenceTime = today.diff(dayjs(date), "day");
    if (differenceTime > 0) {
      return "expired";
    }

    if (differenceTime == 0) {
      return "one-day-remaining";
    }

    if (differenceTime > -7) {
      return "one-week-remaining";
    }

    if (differenceTime > -30) {
      return "thirty-days-remaining";
    }

    if (differenceTime > 0) {
      return "expired";
    }

    return differenceTime;
  };

  if (isLoading) return <Loading center />;

  return (
    <Wrapper>
      <div className={`general-container ${distanceToToday(deadline)}`}>
        <div className="title">
          <h3>{taskName || "Name of the task"}</h3>
        </div>
        <div className="properties">
          <p className="property">
            <span className="icon">
              <BsHourglassSplit />
            </span>
            {dayjs(dayjs()).to(addingOneDay(deadline))}
          </p>
          <p className="property">
            <span className="icon">
              <IoMdTime />
            </span>
            {dayjs(deadline).format("DD/MM/YYYY") || "Deadline of the task"}
          </p>
          <p className="property">
            <span className="icon">
              <FaRegCalendarAlt />
            </span>
            {dayjs(createdAt).format("DD/MM/YYYY") || "created At"}
          </p>
          <p className="property">
            <span className="icon">
              <BiCategoryAlt />
            </span>
            {category || "category of the task"}
          </p>
          <p className="property">
            <span className="icon">
              <AiOutlineTag />
            </span>
            <div className="tags-container">
              {tags.map((tag, index) => {
                return (
                  <span key={index} className="tag-container">
                    #{tag}
                  </span>
                );
              }) || "tags of the task"}
            </div>
          </p>
        </div>

        <div className="button-container">
          <Link to={`/task/?taskId=${taskId}`}>
            <button
              className="edit-button"
              onClick={() => {
                dispatch(currentSection({ section: "task" }));
              }}
            >
              edit
            </button>
          </Link>
          <button className="edit-button" onClick={() => onDeleteClicked()}>
            delete
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default SingleTaskDisplay;
