import React, { useState, useEffect } from "react";
import Wrapper from "../assets/wrappers/SearchFormDiary";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FormRowSelect } from "../components";
import { handleSearchDiaryChange } from "../features/allDiary/allDiarySlice";
const SearchFormDiary = ({ showSidebar }) => {
  const { yearList, monthList, searchValuesDiary } = useSelector(
    (store) => store.allDiary
  );
  const dispatch = useDispatch();
  const [localSearchValues, setLocalSearchValues] = useState(searchValuesDiary);
  let { year, month } = localSearchValues;

  const handleSearch = (e) => {
    dispatch(
      handleSearchDiaryChange({ name: e.target.name, value: e.target.value })
    );
  };

  useEffect(() => {
    setLocalSearchValues(searchValuesDiary);
  }, [searchValuesDiary, localSearchValues]);
  return (
    <Wrapper>
      <div
        className={`search-form-diary-container ${
          !showSidebar && "search-form-diary-hide-sidebar"
        }`}
      >
        <FormRowSelect
          list={yearList}
          labelText="Year"
          value={year}
          name="year"
          onChange={handleSearch}
        />
        <FormRowSelect
          list={monthList}
          labelText="Month"
          value={month}
          name="month"
          onChange={handleSearch}
        />
        <div className="create-button-container">
          <Link to="/diary-entry">
            <button className="general-button">Create new Diary Entry</button>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default SearchFormDiary;
