import React from "react";
import { useEffect } from "react";

const Analysis = () => {
  useEffect(() => {
    console.log("analysis useeffect");
  });
  return <div>Analysis</div>;
};

export default Analysis;
